button:focus {
    outline: none;
}

.btn {
    padding: 16px 20px;
    line-height: 1;
    font-weight: $font-weight-bold;
}

.btn-rounded {
    border-radius: 24px;
}

.btn-app-download {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid $border-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;   
    padding: 0;
}