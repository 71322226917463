.pricing-card {
    border: 0;
    border-top: 4px solid transparent;
    border-radius: 6px;
    box-shadow: 0 2px 13px 0 rgba(218, 215, 215, 0.5);
    padding: 55px 35px 35px;
    text-align: center;

    .payment-period {
        margin-bottom: 28px;
    }

    p {
        font-size: 14px;
        color: $text-muted;
    }

    &:not(.active) {
        button {
            opacity: 0.6;
        }
    }
}

.landing-faq-card {
    box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5);
    border: 0;
    border-radius: 0;

    .card-header {

        a{
            color: inherit;
            text-decoration: none;

            &:hover {
                color: inherit;
            }
        }
        h6{
            .landing-faq-section &{
                font-weight: $font-weight-normal;
            }
            
        }

        i {
            color: #152c5b;
        }
    }

    .card-body {
        p {
            font-size: 14px;
        }
    }
}

.feature-faq-card {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #e9ecf1;

    &:last-child {
        border-bottom: 0;
    }

    .card-header {
        border-bottom: 0;
        padding-top: 23px;
        padding-bottom: 23px;
        padding-left: 0;

        h6{
            line-height: 1.25;
        }

        a{
            color: inherit;

            &:hover {
                text-decoration: none;
            }

            i {
                color: #152c5b;
                font-size: 18px;
            }
        }
    }

    .card-body {
        background-color: theme-color(secondary);

        p{
            font-size: 14px;
            line-height: 1.76;
        }
    }
}

.faq-card {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5);
    margin-bottom: 32px;

    .card-header {
        padding: 22px 42px 22px 54px;
        border-bottom: 0;

        a{
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .card-body {
        background-color: theme-color(secondary);
    }
}

.career-card {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5);

    .card-body {
        padding: 34px 105px 42px 62px;

        @media (max-width: 767px) {
            padding: 15px 35px;
        }
    }

    .careers-list-group {
        .list-group-item {
            padding: 30px 0;

            @media (min-width: 768px) {
                display: flex;
            }

            .position-title {
                margin-bottom: 0;
            }

            .position-location {
                font-size: 14px;
                line-height: 2.57;
                color: $text-muted;
                margin-bottom: 0;
            }

            .apply-btn {
                margin: auto 0 auto auto;

                @media (max-width: 767px) {
                    display: flex;
                    margin: 10px 0 0;
                }
            }
        }
    }
}